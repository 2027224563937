/** The module that does all the crazy DOM manipulation and reconcilation. */
const updateDivWithoutChildren = (props, component) => {
    var _a;
    let div;
    // We nuke the old DOM if the DOM type doesn't match between rerenders.
    // React also does this: https://reactjs.org/docs/reconciliation.html#tradeoffs
    if (component !== undefined && component.realDOMNode instanceof HTMLDivElement) {
        div = component.realDOMNode;
    }
    else {
        div = document.createElement('div');
    }
    if (component === undefined || component.currentElement.props.className !== props.className) {
        div.className = (_a = props.className) !== null && _a !== void 0 ? _a : '';
    }
    return div;
};
const updateSpanWithoutChildren = (props, component) => {
    var _a;
    // Not so different from the div case
    let span;
    if (component !== undefined && component.realDOMNode instanceof HTMLSpanElement) {
        span = component.realDOMNode;
    }
    else {
        span = document.createElement('span');
    }
    if (component === undefined || component.currentElement.props.children !== props.children) {
        span.innerText = (_a = props.children) !== null && _a !== void 0 ? _a : '';
    }
    return span;
};
const updateAnchorWithoutChildren = (props, component) => {
    var _a, _b;
    // Not so different from the div case
    let a;
    if (component !== undefined && component.realDOMNode instanceof HTMLAnchorElement) {
        a = component.realDOMNode;
    }
    else {
        a = document.createElement('a');
    }
    if (component === undefined || component.currentElement.props.href !== props.href) {
        a.href = (_a = props.href) !== null && _a !== void 0 ? _a : '';
    }
    if (component === undefined || component.currentElement.props.children !== props.children) {
        a.innerText = (_b = props.children) !== null && _b !== void 0 ? _b : '';
    }
    return a;
};
const updateInputWithoutChildren = (props, component) => {
    var _a;
    let input;
    if (component !== undefined && component.realDOMNode instanceof HTMLInputElement) {
        input = component.realDOMNode;
    }
    else {
        input = document.createElement('input');
    }
    if (component === undefined || component.currentElement.props.value !== props.value) {
        input.value = (_a = props.value) !== null && _a !== void 0 ? _a : '';
    }
    // Here is the `input` specific part.
    // We cannot set event listeners directly. We have to add or remove them.
    // This is why supporting all available DOM props is a little hard.
    if (component !== undefined && component.currentElement.props.onChange !== undefined) {
        input.removeEventListener('input', component.currentElement.props.onChange);
    }
    if (props.onChange !== undefined) {
        input.addEventListener('input', props.onChange);
    }
    return input;
};
const updateImageWithoutChildren = (props, component) => {
    var _a, _b, _c;
    let img;
    if (component !== undefined && component.realDOMNode instanceof HTMLImageElement) {
        img = component.realDOMNode;
    }
    else {
        img = document.createElement('img');
    }
    if (component === undefined || component.currentElement.props.src !== props.src) {
        img.src = (_a = props.src) !== null && _a !== void 0 ? _a : '';
    }
    if (component === undefined || component.currentElement.props.alt !== props.alt) {
        img.alt = (_b = props.alt) !== null && _b !== void 0 ? _b : '';
    }
    if (component === undefined || component.currentElement.props.className !== props.className) {
        img.className = (_c = props.className) !== null && _c !== void 0 ? _c : '';
    }
    return img;
};
const updateDOMWithoutChildren = (virtualDOM, oldComponent) => {
    let element;
    if (virtualDOM.component === 'div') {
        element = updateDivWithoutChildren(virtualDOM.props, oldComponent);
    }
    else if (virtualDOM.component === 'span') {
        element = updateSpanWithoutChildren(virtualDOM.props, oldComponent);
    }
    else if (virtualDOM.component === 'a') {
        element = updateAnchorWithoutChildren(virtualDOM.props, oldComponent);
    }
    else if (virtualDOM.component === 'input') {
        element = updateInputWithoutChildren(virtualDOM.props, oldComponent);
    }
    else if (virtualDOM.component === 'img') {
        element = updateImageWithoutChildren(virtualDOM.props, oldComponent);
    }
    else {
        throw new Error();
    }
    return element;
};
export default updateDOMWithoutChildren;
