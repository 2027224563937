/** The module contains runtime support for the render phase. */
import { getScheduler } from './react-scheduler';
let currentComponent = null;
const globalEffectQueue = [];
export const useState = (defaultValue) => {
    const statefulComponent = currentComponent;
    if (statefulComponent === null) {
        throw new Error();
    }
    const { states, currentStateIndex } = statefulComponent;
    if (currentStateIndex > states.length || currentStateIndex < 0) {
        throw new Error();
    }
    if (currentStateIndex == states.length) {
        const constructedNewStateSlot = [
            defaultValue,
            (newValue) => {
                if (states[currentStateIndex][0] === newValue) {
                    // Bailout of update if state doesn't change.
                    return;
                }
                // Tells schedular how to rerender!
                getScheduler().addJob(() => {
                    states[currentStateIndex][0] = newValue;
                    return [statefulComponent, renderWithRuntime(statefulComponent)];
                });
            },
        ];
        states.push(constructedNewStateSlot);
        statefulComponent.currentStateIndex += 1;
        return constructedNewStateSlot;
    }
    statefulComponent.currentStateIndex += 1;
    return states[currentStateIndex];
};
export const useEffect = (effect) => {
    globalEffectQueue.push(effect);
};
export const renderWithRuntime = (statefulComponent) => {
    // Inject hooks runtime for rendering.
    currentComponent = statefulComponent;
    const partiallyReducedNode = statefulComponent.component(statefulComponent.currentElement.props);
    // De-inject hooks runtime for rendering.
    statefulComponent.currentStateIndex = 0;
    currentComponent = null;
    return partiallyReducedNode;
};
export const runEffects = () => {
    globalEffectQueue.forEach((effect) => effect());
    // Running effects will not add new effects, assuming rules of hooks are followed.
    globalEffectQueue.length = 0;
};
