import { useState as unstrictlyTypedUseState, useEffect } from './renderer-runtime';
import mountToDOM from './react-dom';
// Expose a setState that's more nicely typed instead of using any.
// The framework has to use any since it's too dynamic.
const useState = (initialValue) => unstrictlyTypedUseState(initialValue);
/**
 * The adapter for TypeScript and babel to transform JSX.
 * We name it the same way React did, and with the exact signature.
 * In this way, we are able to reuse the existing toolchain!
 */
const createElement = (component, props, ...children) => {
    props = props !== null && props !== void 0 ? props : {};
    if (component === 'div') {
        return {
            component,
            props,
            children: children.map((child) => typeof child === 'string'
                ? { component: 'span', props: { children: child }, children: [] }
                : child),
        };
    }
    if (component === 'img') {
        return { component, props, children: [] };
    }
    if (component === 'input') {
        return { component, props, children: [] };
    }
    if (component === 'span') {
        return {
            component,
            props: Object.assign(Object.assign({}, props), { children: children[0] }),
            children: [],
        };
    }
    if (component === 'a') {
        return {
            component,
            props: Object.assign(Object.assign({}, props), { children: children[0] }),
            children: [],
        };
    }
    return {
        component,
        props: Object.assign(Object.assign({}, props), { children }),
        children: [],
    };
};
/**
 * This export code might look a little weird, but it's used to support something like:
 *
 * ```typescript
 * import React, { useState } from 'sam-react';
 *
 * useState(...); // useful shorthand
 * React.createElement(...); // useful for babel.
 * ```
 */
const ReactWithoutDefault = { useState, useEffect, createElement, mountToDOM };
export { ReactWithoutDefault as default, useState, useEffect, createElement, mountToDOM };
