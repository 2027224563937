import Scheduler from './scheduler';
let scheduler = null;
export const registerJobRunner = (runner) => {
    scheduler = new Scheduler(runner);
};
export const getScheduler = () => {
    if (scheduler === null) {
        throw new Error();
    }
    return scheduler;
};
